<template>
  <el-row class="gov-approve">
    <el-col class="zt-page-head">设计奖补申请</el-col>
    <el-col>
      <div class="zt-block demand-query">
        <el-form :model="searchForm" inline style="width: 100%; height: 40px">
          <el-row type="flex" align="middle">
            <el-col :span="16">
              <el-form-item label="申请日期">
                <el-date-picker
                  v-model="searchForm.daterange"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  style="width: 380px"
                  @change="getDate"
                ></el-date-picker>
              </el-form-item>
              <el-form-item label="当前审批状态">
                <el-select
                  v-model="searchForm.state"
                  placeholder="全部"
                  @change="handleChange"
                >
                  <el-option
                    v-for="item in orderStateOption"
                    :key="item.optionCode"
                    :label="item.optionValue"
                    :value="item.optionCode"
                  ></el-option>
                </el-select>
              </el-form-item>
              <!-- <el-button @click="exportMerge">导出合并的表格</el-button> -->
            </el-col>
            <el-col :span="8" class="align-r">
              <el-form-item>
                <el-input
                  v-model="searchForm.keywords"
                  placeholder="企业名称"
                  style="width: 250px"
                >
                  <el-button
                    @click="search()"
                    slot="append"
                    icon="el-icon-search"
                  ></el-button>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="zt-block" style="height: calc(100% - 92px)">
        <el-row class="p20" type="flex" justify="space-between">
          <el-col class="align-r">
            <el-button
              type="primary"
              @click="exportExcel(2)"
              :loading="downloading2"
              >申请表批量下载</el-button
            >
            <el-button style="margin-left:20px"
              type="primary"
              @click="exportExcel(3)"
              :loading="downloading3"
              >申报材料批量下载</el-button
            >
            <el-button style="margin-left:20px"
              type="primary"
              @click="exportExcel(1)"
              :loading="downloading1"
              >导出汇总表
            </el-button>
          </el-col>
        </el-row>
        <div class="list" style="height: calc(100% - 60px); overflow-y: auto">
          <el-table
            :data="list"
            id="excel"
            ref="multipleTable"
            @selection-change="handleSelectionChange"
            style="width: 100%"
          >
            <el-table-column type="selection" align="center"></el-table-column>
            <el-table-column type="index" label="序号" width="100">
              <template slot-scope="scope">{{
                queryOpt.page.pageCount * (queryOpt.page.pageNumber - 1) +
                scope.$index +
                1
              }}</template>
            </el-table-column>
            <!-- <el-table-column
              prop="id"
              label="申请编号"
              width="320"
              align="center"
            ></el-table-column> -->
            <el-table-column prop="submitAt" label="申请日期" align="center">
              <template slot-scope="scope">
                <div v-if="scope.row.submitAt">
                  {{ scope.row.submitAt | moment("YYYY/MM/DD") }}
                </div>
                <div v-else></div>
              </template>
            </el-table-column>
            <el-table-column
              prop="compName"
              label="企业名称"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="is_in_brand_group"
              label="南康家具集体商标"
              align="center"
            >
              <template slot-scope="scope">
                <div v-if="scope.row.is_in_brand_group === 1">是</div>
                <div v-if="scope.row.is_in_brand_group === 0">否</div>
              </template>
            </el-table-column>
            <el-table-column
              prop="approveState"
              label="当前审批状态"
              align="center"
            >
              <!-- 审核中 审核不通过 审核通过 -->
              <template slot-scope="scope">
                <template v-if="$store.state.loginUser.comp.comp_type === 9901">
                  <div
                    v-if="
                      scope.row.approves.find((item) => item.code === '2501')
                    "
                  >
                    <div
                      v-if="
                        scope.row.approves.find((item) => item.code === '2501')
                          .state === 1
                      "
                    >
                      审核通过
                    </div>
                    <div
                      v-if="
                        scope.row.approves.find((item) => item.code === '2501')
                          .state === 2
                      "
                    >
                      审核不通过
                    </div>
                  </div>

                  <div v-else>审核中</div>
                </template>
                <template v-if="$store.state.loginUser.comp.comp_type === 9902">
                  <div
                    v-if="
                      scope.row.approves.find((item) => item.code === '2502')
                    "
                  >
                    <div
                      v-if="
                        scope.row.approves.find((item) => item.code === '2502')
                          .state === 1
                      "
                    >
                      审核通过
                    </div>
                    <div
                      v-if="
                        scope.row.approves.find((item) => item.code === '2502')
                          .state === 2
                      "
                    >
                      审核不通过
                    </div>
                  </div>

                  <div v-else>审核中</div>
                </template>
                <template v-if="$store.state.loginUser.comp.comp_type === 9903">
                  <div
                    v-if="
                      scope.row.approves.find((item) => item.code === '2503')
                    "
                  >
                    <div
                      v-if="
                        scope.row.approves.find((item) => item.code === '2503')
                          .state === 1
                      "
                    >
                      审核通过
                    </div>
                    <div
                      v-if="
                        scope.row.approves.find((item) => item.code === '2503')
                          .state === 2
                      "
                    >
                      审核不通过
                    </div>
                  </div>

                  <div v-else>审核中</div>
                </template>
              </template>
            </el-table-column>
            <el-table-column prop="state" label="审批后处理" align="center">
              <template slot-scope="scope">
                <div v-if="scope.row.state == 1">--</div>
                <div v-if="scope.row.state == 2">--</div>
                <div v-if="scope.row.state == 3">--</div>
                <div v-if="scope.row.state == 12">--</div>
                <div v-if="scope.row.state == 4">暂无</div>
                <div v-if="scope.row.state == 5">已签约</div>
                <div v-if="scope.row.state == 6">已公示</div>
                <div v-if="scope.row.state == 7">奖补发放</div>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-row>
                  <el-col>
                    <el-button
                      @click="
                        goto('/approve/allowance/' + scope.row.id + '?type=2')
                      "
                      type="text"
                      >查看信息</el-button
                    >
                    <template
                      v-if="
                        $store.state.loginUser.comp.comp_type === 9903 &&
                        [5, 6, 7].includes(scope.row.state)
                      "
                    >
                      <el-popover
                        placement="bottom"
                        title="更新"
                        width="140"
                        trigger="click"
                      >
                        <el-button
                          type="primary"
                          size="mini"
                          @click="approveStateSubmit(scope.row, 'jb')"
                          >奖补</el-button
                        >
                        <el-button
                          type="primary"
                          size="mini"
                          @click="approveStateSubmit(scope.row, 'gs')"
                          >公示</el-button
                        >
                        <el-button slot="reference" type="text">更新</el-button>
                      </el-popover>
                    </template>

                    <!-- 设计中心 -->
                    <template
                      v-if="$store.state.loginUser.comp.comp_type === 9901"
                    >
                      <el-button
                        v-if="
                          scope.row.approves.find(
                            (item) => item.code === '2501'
                          ) &&
                          !scope.row.approves.find(
                            (item) => item.code === '2501'
                          ).signedAt
                        "
                        @click="goSign(scope.row)"
                        type="text"
                        >去签章</el-button
                      >

                      <el-button
                        v-if="
                          scope.row.state === 2 &&
                          scope.row.approves.length === 0
                        "
                        type="text"
                        @click="
                          goto('/approve/allowance/' + scope.row.id + '?type=1')
                        "
                        >审批</el-button
                      >
                    </template>

                    <!-- 升规办 -->
                    <template
                      v-if="$store.state.loginUser.comp.comp_type === 9902"
                    >
                      <el-button
                        v-if="
                          scope.row.state === 2 &&
                          scope.row.approves.length === 1
                        "
                        type="text"
                        @click="
                          goto('/approve/allowance/' + scope.row.id + '?type=1')
                        "
                        >审批</el-button
                      >
                    </template>

                    <!-- 家促局 -->
                    <template
                      v-if="$store.state.loginUser.comp.comp_type === 9903"
                    >
                      <el-button
                        type="text"
                        v-if="
                          scope.row.state === 2 &&
                          scope.row.approves.length === 2
                        "
                        @click="
                          goto('/approve/allowance/' + scope.row.id + '?type=1')
                        "
                        >审批</el-button
                      >
                      <el-button
                        v-if="
                          scope.row.approves.find(
                            (item) => item.code === '2503'
                          ) &&
                          !scope.row.approves.find(
                            (item) => item.code === '2503'
                          ).signedAt
                        "
                        @click="goSign(scope.row)"
                        type="text"
                        >去签章</el-button
                      >
                    </template>
                    <!-- 流程结束才显示下载按钮(同工厂) -->
                    <el-button
                      v-if="[3, 4, 5, 6, 7].includes(scope.row.state)"
                      type="text"
                      @click="singleAffixDownload(scope.row)"
                      >申报材料下载</el-button
                    >
                  </el-col>
                </el-row>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div style="padding: 12px">
          <el-pagination
            background
            layout="prev, pager, next"
            :current-page.sync="queryOpt.page.pageNumber"
            :page-size="queryOpt.page.pageCount"
            :total="total"
          ></el-pagination>
        </div>
      </div>
    </el-col>
    <el-dialog
      @close="refreshItems"
      :title="popTitle"
      :visible.sync="popVisible"
      :destroy-on-close="true"
    >
      <div v-if="popTitle == '签章'">
        <Sign
          :popTitle="popTitle"
          @data="refreshItems"
          @signState="signState"
          @hide="hide"
        ></Sign>
      </div>
      <div v-if="popTitle == '遇到问题'">
        <SignBack :popTitle="popTitle" @hide="popVisible = false"></SignBack>
      </div>
    </el-dialog>
  </el-row>
</template>
<script>
import _ from "lodash";
import { Loading } from "element-ui";
import Sign from "@/components/sign/sign.vue";
import SignBack from "@/components/sign/signBack.vue";
import { designAllowanceList, setApplyedFlow } from "@/service/factory";
import { debounce } from "lodash";
import { designCompany } from "@/utils/constant";
import {
  contractAddr,
  contractView,
  sealIn,
  contractInfo,
} from "@/service/contract";
import { mapGetters } from "vuex";
export default {
  components: {
    Sign,
    SignBack,
  },
  computed: {
    orderStateOption() {
      return [
        {
          optionCode: null,
          optionValue: "全部",
        },
        {
          optionCode: 1,
          optionValue: "审核中",
        },
        {
          optionCode: 3,
          optionValue: "审核不通过",
        },
        {
          optionCode: 2,
          optionValue: "审核通过",
        },
      ];
    },
  },
  watch: {
    queryOpt: {
      handler() {
        this.debounceRefreshItems();
      },
      deep: true,
    },
  },
  data() {
    return {
      radio: "jb",
      isActive: true,
      gov: {
        departmentName: "南康工业(家具)",
      },
      popTitle: null,
      popVisible: false,
      ruleForm: {
        daterange: [],
      },
      downloadShow: false,
      approveStateShow: false,
      downloading1: false,
      downloading2: false,
      downloading3: false,
      multipleSelection: [],
      searchForm: {
        daterange: [],
        state: "",
        keywords: "",
      },
      queryOpt: {
        where: {
          submitAt: {
            start: "",
            end: "",
          },
          states: null, //1 已保存待提交 2 审核中 3 审核不通过 4 审核通过 5 已签约 6 公示 7 发放奖补
          key: "",
          approveState: null,
        },
        page: {
          pageNumber: 1,
          pageCount: 15,
        },
        order: [["createdAt", "desc"]],
      },
      list: [],
      total: 0,
    };
  },
  created() {
    this.refreshItems();
    this.debounceRefreshItems = debounce(this.refreshItems, 500);
  },
  methods: {
    export_file_to_zip1() {},
    singleAffixDownload(row) {
      const { compName, attachment, contract_no, approves, id } = row;
      this.$confirm("下载为当前预览格式,确认下载?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          let a1 = approves.find((item) => item.code === "2501");
          let a3 = approves.find((item) => item.code === "2503");
          let downloadlist = [];
          if (a1 && a1.contract_no) {
            downloadlist.push(a1.contract_no);
          }
          if (a3 && a3.contract_no) {
            downloadlist.push(a3.contract_no);
          }
          const fileData = [
            {
              folderName: "营业执照",
              fileData: attachment.license,
            },
            {
              folderName: "法定代表人信息",
              fileData: [attachment.idCard.pros, attachment.idCard.cons],
            },
            {
              folderName: "设计合同",
              fileData: attachment.contracts,
            },
            {
              folderName: "设计图纸",
              fileData: attachment.drawings,
            },
            {
              folderName: "税务发票",
              fileData: attachment.receipts,
            },
            {
              folderName: "付款凭证",
              fileData: attachment.vouchers,
            },
            {
              folderName: "申请表",
              fileData: downloadlist,
            },
          ];
          this.exportZip(fileData, `${compName}${id}申报材料`);
        })
        .catch((e) => {
          console.error(e.message);
          this.$message.info("取消");
        });
    },
    viewContract(row) {
      // console.log('lllllll',row);
      if (row.approves) {
        let type = this.$store.state.loginUser.comp.comp_type;
        if (type === 9901) {
          const obj = row.approves.find((item) => item.code === "2501");
          if (obj) {
            console.error("设计中心合同编号", obj.contract_no);
            window.open(contractView(obj.contract_no));
          } else {
            this.$message.error("审批之后才能预览合同信息");
          }
        }
        if (type === 9902) {
          const obj = row.approves.find((item) => item.code === "2502");
          if (obj) {
            console.error("升规办合同编号", obj.contract_no);
            window.open(contractView(obj.contract_no));
          } else {
            this.$message.error("审批之后才能预览合同信息");
          }
        }
        if (type === 9903) {
          const obj = row.approves.find((item) => item.code === "2503");
          if (obj) {
            console.error("家促局合同编号", obj.contract_no);
            window.open(contractView(obj.contract_no));
          } else {
            this.$message.error("审批之后才能预览合同信息");
          }
        }
      }
    },
    autoInSeal(contract_no) {
      sealIn({
        sign_positions: [
          {
            pagenum: 0,
            x: 250,
            y: 150,
          },
        ],
        contract_no,
        comp_id: this.comp_id,
      }).then((rst) => {});
    },
    goSign(row) {
      this.comp_id = row.compId;
      if (this.$store.state.loginUser.comp.cert_stat != 2) {
        this.$message.error("企业未认证");
        return;
      } else {
        this.$confirm("确认是否发起签章?", "温馨提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          closeOnClickModal: false,
          type: "warning",
        })
          .then(() => {
            const obj = row.approves.find(
              (item) => item.code === row.currentNode
            );
            if (obj && obj.contract_no) {
              console.log("去签约传入的合同号", obj.contract_no);
              this.going(obj.contract_no);
            } else {
              this.$message.error("合同不存在!");
            }
          })
          .catch((e) => {
            console.log(e);
            this.$router.push({ path: "/approve/allowance" });
            this.$message.info("取消");
          });
      }
    },
    going(contract_no, user_id) {
      this.signLoading = Loading.service({
        fullscreen: true,
        text: "拼命加载中....",
      });
      contractAddr(contract_no)
        .then((rst) => {
          console.log("contractAddr", rst);
          if (rst && rst.url) {
            this.sign(rst.url, contract_no);
          }
        })
        .catch((e) => {
          console.error(e);
          this.$message.error(e.message);
          this.signLoading.close();
        });
    },
    sign(url, contract_no) {
      this.contract_no = contract_no;
      this.signLoading.close();
      if (url) window.open(url);
      this.signState("签章");
    },
    hide() {
      this.popVisible = false;
      this.$message.success("状态已更新");
      this.getContractInfo(this.contract_no);
    },
    getContractInfo(contract_no) {
      contractInfo(contract_no).then((rst) => {
        if ([4].includes(rst.stat)) {
          this.autoInSeal(contract_no);
        }
      });
    },
    signState(text) {
      this.popTitle = text;
      this.popVisible = true;
    },
    goto(link) {
      this.$router.push(link);
    },
    downloadSubmit(id) {
      // todo
    },
    // 需重新处理
    approveStateSubmit(row, opt) {
      if (row.state === 6 && opt === "gs") {
        this.$message.error("当前状态为已公示,无需更改!");
        return false;
      }
      if (row.state === 7 && opt === "jb") {
        this.$message.error("当前状态为奖补发放,无需更改!");
        return false;
      }
      setApplyedFlow(row.id, { opt }).then((rst) => {
        this.$message.success("设置成功");
        this.refreshItems();
      });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    exportSummary() {
      const data = _.groupBy(
        _.sortBy(
          this.multipleSelection.map((item) => {
            return {
              id: item.id,
              desginCompName: item.desginCompName,
              submitAt: this.$moment(item.submitAt).format("YYYY-MM-DD"),
              compName: item.compName,
              fee: item.fee,
              applyAmount: item.applyAmount,
              percent: Math.round((item.applyAmount / item.fee) * 100) + "%",
              approveAmount:
                item.result && item.result.approveAmount
                  ? item.result.approveAmount
                  : "",
              is_in_brand_group:
                item.is_in_brand_group === 1
                  ? "是"
                  : item.is_in_brand_group === 0
                  ? "否"
                  : "",
              item: item.result ? item.result.item : "",
            };
          }),
          ["desginCompName"]
        ),
        "desginCompName"
      );
      return new Promise((resolve) => {
        import("@/vendor/Export2Excel").then((excel) => {
          excel.funtransformF(
            data,
            "汇总表" + this.$moment().format("YYYYMMDDHHmmss")
          );
          resolve();
        });
      });
    },
    async exportExcel(type) {
      // type 1: 导出汇总表 2:申请表批量下载 3: 申请附件批量下载
      if (this.multipleSelection.length) {
        this[`downloading${type}`] = true;
        if (type === 1) {
          await this.exportSummary();
        } else if (type === 2) {
          await this.exportApply();
        } else if (type === 3) {
          await this.exportAffix();
        }
        this.$refs.multipleTable.clearSelection();
        this[`downloading${type}`] = false;
        this.$message.warning("导出文件较大,请耐心等待!");
      } else {
        this.$message({
          message: "请至少选择一项",
          type: "warning",
        });
      }
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          if (j === "percent") {
            return Math.round((v.applyAmount / v.fee) * 100) + "%";
          }
          if (j === "submitAt") {
            return this.$moment(v[j]).format("YYYY-MM-DD");
          }
          if (j === "is_in_brand_group") {
            return v.is_in_brand_group === 1
              ? "是"
              : v.is_in_brand_group === 0
              ? "否"
              : "";
          }
          return v[j];
        })
      );
    },
    vadaliteChecked() {},
    // 批量导出申请表
    async exportApply() {
      // 设计中心或者升规办不通过 a3 就不存在,只会有一份文件
      const fileData = this.multipleSelection.map((record) => {
        let a1 = record.approves.find((item) => item.code === "2501");
        let a3 = record.approves.find((item) => item.code === "2503");
        let downloadlist = [];
        if (a1 && a1.contract_no) {
          downloadlist.push(a1.contract_no);
        }
        if (a3 && a3.contract_no) {
          downloadlist.push(a3.contract_no);
        }
        return {
          folderName: `${record.compName}申请表${record.id}`,
          fileData: downloadlist,
        };
      });
      import("@/vendor/Export2Zip").then((excel) => {
        excel.export_file_to_zip1(
          fileData,
          `申请表${this.$moment().format("YYYYMMDDHHmmss")}`
        );
      });
    },
    // 批量导出申报材料
    async exportAffix() {
      // 设计中心或者升规办不通过 a3 就不存在,只会有一份文件
      const fileData = this.multipleSelection.map((record) => {
        const attachment = record.attachment;
        let a1 = record.approves.find((item) => item.code === "2501");
        let a3 = record.approves.find((item) => item.code === "2503");
        let downloadlist = [];
        if (a1 && a1.contract_no) {
          downloadlist.push(a1.contract_no);
        }
        if (a3 && a3.contract_no) {
          downloadlist.push(a3.contract_no);
        }
        return {
          folderName: record.compName + record.id,
          folderData: [
            {
              fileName: "营业执照",
              fileData: attachment.license,
            },
            {
              fileName: "法定代表人信息",
              fileData: [attachment.idCard.pros, attachment.idCard.cons],
            },
            {
              fileName: "设计合同",
              fileData: attachment.contracts,
            },
            {
              fileName: "设计图纸",
              fileData: attachment.drawings,
            },
            {
              fileName: "税务发票",
              fileData: attachment.receipts,
            },
            {
              fileName: "付款凭证",
              fileData: attachment.vouchers,
            },
            {
              fileName: "申请表",
              fileData: downloadlist,
            },
          ],
        };
      });
      console.log("exportAffix", fileData);

      import("@/vendor/Export2Zip").then((excel) => {
        excel.export_folder_to_zip(
          fileData,
          `申报材料汇总${this.$moment().format("YYYYMMDDHHmmss")}`
        );
      });
    },
    // 单条申请记录导出申报材料
    exportZip(fileData, fileName) {
      import("@/vendor/Export2Zip")
        .then((excel) => {
          excel.export_file_to_zip1(
            fileData,
            `${fileName}${this.$moment().format("YYYYMMDDHHmmss")}`
          );
        })
        .catch((e) => {
          this.$message.error(e.message);
        });
    },
    getDate(e) {
      if (e) {
        this.queryOpt.where.submitAt.start = new Date(e[0]).getTime();
        this.queryOpt.where.submitAt.end = new Date(e[1]).getTime();
      } else {
        this.queryOpt.where.submitAt.start = null;
        this.queryOpt.where.submitAt.end = null;
      }
    },
    handleChange() {
      if (this.searchForm.state === -1) {
        this.queryOpt.where.approveState = null;
      } else {
        this.queryOpt.where.approveState = this.searchForm.state;
      }
    },
    search() {
      this.queryOpt.where.key = this.searchForm.keywords;
    },
    refreshItems() {
      designAllowanceList(this.queryOpt)
        .then((rst) => {
          this.list = rst.rows.map((item) => {
            return { ...item, is_in_brand_group: item.comp.is_in_brand_group };
          });
          this.total = rst.count;
        })
        .catch((err) => {
          this.$message.error(err.message);
        });
    },
  },
};
</script>
<style lang="less" scoped>
.gov-approve {
  // padding: 20px;
}
</style>